<template>
  <div id="table-management" class="site-container">
    <div class="general-datatable-wp">
      <v-row no-gutters>
        <v-col cols="12" class="general-datatable-content">
          <v-data-table
            :headers="headers"
            :items="dataFormGroup"
            no-data-text="No Data"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1"
            @page-count="pageCount = $event"
            :mobile-breakpoint="0"
          >
            <template v-slot:top>
              <v-row no-gutters id="table-search">
                <v-col cols="12" md="4" style="visibility: hidden">
                  <div class="total-heading total-heading-left">
                    <h1>Total User: 0</h1>
                  </div>
                  <div class="user-roles-information">
                    <div class="user-role-item">
                      <div class="user-role-total">
                        <p>0</p>
                      </div>
                      <div class="user-role-name">
                        <span>Superadmin</span>
                      </div>
                    </div>
                    <div class="user-role-item">
                      <div class="user-role-total">
                        <p>0</p>
                      </div>
                      <div class="user-role-name">
                        <span>Admin</span>
                      </div>
                    </div>
                    <div class="user-role-item">
                      <div class="user-role-total">
                        <p>0</p>
                      </div>
                      <div class="user-role-name">
                        <span>Operator</span>
                      </div>
                    </div>
                    <div class="user-role-item">
                      <div class="user-role-total">
                        <p>0</p>
                      </div>
                      <div class="user-role-name">
                        <span>Site Engineer</span>
                      </div>
                    </div>
                    <div class="user-role-item">
                      <div class="user-role-total">
                        <p>0</p>
                      </div>
                      <div class="user-role-name">
                        <span>Home User</span>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-spacer />
                <v-col cols="12" md="5">
                  <div class="switch-component switch-custom justify-content-end">
                    <button class="dialog-default-button btn_UserGroup data-table-group-size dialog-bg-grey-button switch-btn add-group-btn button-hover " @click="openDialogAddUserGroup">
                      Add New User Group
                    </button>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.accessible_menus`]="{ item }">
              <div class="d-flex justify-content-center">
                <v-btn
                  :ripple="false"
                  icon
                  v-if="item.accessible_menus.multi_site.view"
                  title="Multi Site"
                >
                  <img src="@/assets/tables/multi-site-screen.svg" />
                </v-btn>
                <v-btn
                  :ripple="false"
                  icon
                  v-if="item.accessible_menus.single_site.view"
                  title="Single Site"
                >
                  <img src="@/assets/tables/single-site-screen.svg" />
                </v-btn>
                <v-btn
                  :ripple="false"
                  icon
                  v-if="item.accessible_menus.user.view"
                  title="User"
                >
                  <img src="@/assets/tables/user-screen.svg" />
                </v-btn>
                <v-btn
                  :ripple="false"
                  icon
                  v-if="item.accessible_menus.user_group.view"
                  title="User Group"
                >
                  <i style="width: 24px; height: 24px; color: #8e8e93;" class="fas fa-users"></i>
                </v-btn>
                <v-btn
                  :ripple="false"
                  icon
                  v-if="item.accessible_menus.inventory.view"
                  title="Inventory"
                >
                  <img src="@/assets/tables/inventory-screen.svg" />
                </v-btn>
                <v-btn
                  :ripple="false"
                  icon
                  v-if="item.accessible_menus.data_analytic.view"
                  title="Data Analytic"
                >
                  <img src="@/assets/tables/data-analytic-screen.svg" />
                </v-btn>
                <v-btn
                  :ripple="false"
                  icon
                  v-if="item.accessible_menus.asset.view"
                  title="Device"
                >
                  <img src="@/assets/tables/device-screen.svg" />
                </v-btn>
                <v-btn
                  :ripple="false"
                  icon
                  v-if="item.accessible_menus.alarm.view"
                  title="Alarm"
                >
                  <img src="@/assets/tables/alarm-screen.svg" />
                </v-btn>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <template v-if="!item.deleted_at">
                <div class="d-flex justify-content-center">
                  <v-btn
                    :ripple="false"
                    icon
                    title="Edit User Group"
                  >
                    <img 
                      class="icon-action" 
                      src="@/assets/tables/asset_edit.svg" 
                      @click="openDialogEdit(item)" />
                  </v-btn>
                  <v-btn
                    :ripple="false"
                    icon
                    title="Delete User Group"
                    @click="openConfirmDialog(item, deleteUserGroup, 'Delete User Group', 'You are about to delete an user group from the current system. This user group will no longer be registered and controlled.')"
                  >
                    <img 
                      class="icon-action" 
                      src="@/assets/tables/asset_delete.svg" 
                    />
                  </v-btn>
                </div>
              </template>
            </template>
          </v-data-table>
          <div class="data-table-paging">
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="5"
              :dark="true"
            ></v-pagination>
          </div>
        </v-col>
        <v-col cols="12" md="0" sm="0" />
      </v-row>
      <v-dialog
        v-model="dialogAddUserGroup"
        persistent
        content-class="custom-content-modals custom-scroll-modals"
        max-width="856"
      >
        <v-card>
          <v-card-title>Add New User Group</v-card-title>
          <v-card-text class="custom-asset-card">
            <v-form
              ref="addForm"
              @submit.prevent="submitAdd"
              autocomplete="off"
            >
              <div class="scroll_dialog_form">
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="4" class="modal-label">
                    <span>User Group Name</span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="userGroupName"
                      :rules="userGroupNameRules"
                      required
                      autocomplete="off"
                      placeholder="Enter User Group Name"
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="4" class="modal-label">
                    <span>User Group Type</span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-select
                      :items="userGroupTypesList"
                      v-model="userGroupType"
                      :rules="userGroupTypeRules"
                      item-text="name"
                      item-value="value"
                      :class="`${userGroupType ? 'custom-hidden-input' : ''}`"
                      class="custom-select__selections"
                      required
                      filled
                      :menu-props="{ contentClass: 'custom_active_item' }"
                      append-icon="fas fa-caret-down"
                      placeholder="Select User Group Type"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2 screen-label-font">
                  <v-col cols="12" md="12" class="modal-label-margin">
                    <span>Web Access Screens</span>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">Multi Site Dashboard</p>
                    <div class="option-item">
                      <input v-model="selectedViews" class="cursor-pointer" type="checkbox" id="multi-site-view" name="multi-site-view" value="multi_site" />
                      <label for="multi-site-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="selectedActions" class="cursor-pointer" type="checkbox" id="multi-site-action" name="multi-site-action" value="multi_site" />
                      <label for="multi-site-action">Full Control</label>
                    </div>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">Single Site Dashboard</p>
                    <div class="option-item">
                      <input v-model="selectedViews" class="cursor-pointer" type="checkbox" id="single-site-view" name="single-site-view" value="single_site" />
                      <label for="single-site-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="selectedActions" class="cursor-pointer" type="checkbox" id="single-site-action" name="single-site-action" value="single_site" />
                      <label for="single-site-action">Full Control</label>
                    </div>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">Analytic</p>
                    <div class="option-item">
                      <input v-model="selectedViews" class="cursor-pointer" type="checkbox" id="analytic-view" name="analytic-view" value="data_analytic" />
                      <label for="analytic-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="selectedActions" class="cursor-pointer" type="checkbox" id="analytic-action" name="analytic-action" value="data_analytic" />
                      <label for="analytic-action">Full Control</label>
                    </div>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">Device Management</p>
                    <div class="option-item">
                      <input v-model="selectedViews" class="cursor-pointer" type="checkbox" id="device-management-view" name="device-management-view" value="asset" />
                      <label for="device-management-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="selectedActions" class="cursor-pointer" type="checkbox" id="device-management-action" name="device-management-action" value="asset" />
                      <label for="device-management-action">Full Control</label>
                    </div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2 screen-label-font">
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">Alarm Management</p>
                    <div class="option-item">
                      <input v-model="selectedViews" class="cursor-pointer" type="checkbox" id="alarm-management-view" name="alarm-management-view" value="alarm" />
                      <label for="alarm-management-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="selectedActions" class="cursor-pointer" type="checkbox" id="alarm-management-action" name="alarm-management-action" value="alarm" />
                      <label for="alarm-management-action">Full Control</label>
                    </div>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">Inventory Management</p>
                    <div class="option-item">
                      <input v-model="selectedViews" class="cursor-pointer" type="checkbox" id="inventory-management-view" name="inventory-management-view" value="inventory" />
                      <label for="inventory-management-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="selectedActions" class="cursor-pointer" type="checkbox" id="inventory-management-action" name="inventory-management-action" value="inventory" />
                      <label for="inventory-management-action">Full Control</label>
                    </div>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">User Management</p>
                    <div class="option-item">
                      <input v-model="selectedViews" class="cursor-pointer" type="checkbox" id="user-management-view" name="user-management-view" value="user" />
                      <label for="user-management-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="selectedActions" class="cursor-pointer" type="checkbox" id="user-management-action" name="user-management-action" value="user" />
                      <label for="user-management-action">Full Control</label>
                    </div>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">User Group Management</p>
                    <div class="option-item">
                      <input v-model="selectedViews" class="cursor-pointer" type="checkbox" id="user-group-management-view" name="user-group-management-view" value="user_group" />
                      <label for="user-group-management-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="selectedActions" class="cursor-pointer" type="checkbox" id="user-group-management-action" name="user-group-management-action" value="user_group" />
                      <label for="user-group-management-action">Full Control</label>
                    </div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2 screen-label-font">
                  <v-col cols="12" md="12" class="modal-label-margin">
                    <span>App Access Screens</span>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">Energy</p>
                    <div class="option-item">
                      <input v-model="selectedViews" class="cursor-pointer" type="checkbox" id="app-energy-view" name="app-energy-view" value="app_energy" />
                      <label for="app-energy-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="selectedActions" class="cursor-pointer" type="checkbox" id="app-energy-action" name="app-energy-action" value="app_energy" />
                      <label for="app-energy-action">Full Control</label>
                    </div>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">Values</p>
                    <div class="option-item">
                      <input v-model="selectedViews" class="cursor-pointer" type="checkbox" id="app-value-view" name="app-value-view" value="app_value" />
                      <label for="app-value-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="selectedActions" class="cursor-pointer" type="checkbox" id="app-value-action" name="app-value-action" value="app_value" />
                      <label for="app-value-action">Full Control</label>
                    </div>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">Device Management</p>
                    <div class="option-item">
                      <input v-model="selectedViews" class="cursor-pointer" type="checkbox" id="app-device-view" name="app-device-view" value="app_device" />
                      <label for="app-device-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="selectedActions" class="cursor-pointer" type="checkbox" id="app-device-action" name="app-device-action" value="app_device" />
                      <label for="app-device-action">Full Control</label>
                    </div>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">Alarm Management</p>
                    <div class="option-item">
                      <input v-model="selectedViews" class="cursor-pointer" type="checkbox" id="app-alarm-view" name="app-alarm-view" value="app_alarm" />
                      <label for="app-alarm-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="selectedActions" class="cursor-pointer" type="checkbox" id="app-alarm-action" name="app-alarm-action" value="app_alarm" />
                      <label for="app-alarm-action">Full Control</label>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-row no-gutters class="mt-3 pt-2">
                <v-spacer></v-spacer>
                <v-col cols="12" class="text-center">
                  <button
                    class="dialog-default-button dialog-button-add-size dialog-bg-outline-button"
                    :ripple="false"
                    type="button"
                    @click="closeDialogAddUserGroup"
                  >
                    CANCEL
                  </button>
                  <button
                    class="ml-4 dialog-default-button dialog-button-add-size dialog-bg-grey-button"
                    :ripple="false"
                    type="submit"
                  >
                    CONFIRM
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogEditUserGroup"
        persistent
        content-class="custom-content-modals custom-scroll-modals"
        max-width="856"
      >
        <v-card>
          <v-card-title>Edit User Group</v-card-title>
          <v-card-text class="custom-asset-card">
            <v-form
              ref="editForm"
              @submit.prevent="submitEdit"
              autocomplete="off"
            >
              <div class="scroll_dialog_form">
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="4" class="modal-label">
                    <span>User Group Name</span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="editUserGroupName"
                      :rules="userGroupNameRules"
                      required
                      autocomplete="off"
                      placeholder="Enter User Group Name"
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2" style="pointer-events: none;">
                  <v-col cols="12" md="4" class="modal-label">
                    <span>User Group Type</span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-select
                      :items="userGroupTypesList"
                      v-model="editUserGroupType"
                      :rules="userGroupTypeRules"
                      item-text="name"
                      item-value="value"
                      :class="`${editUserGroupType ? 'custom-hidden-input' : ''}`"
                      class="custom-select__selections"
                      required
                      filled
                      :menu-props="{ contentClass: 'custom_active_item' }"
                      append-icon="fas fa-caret-down"
                      placeholder="Select User Group Type"
                      disabled
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2 screen-label-font">
                  <v-col cols="12" md="12" class="modal-label-margin">
                    <span>Web Access Screens</span>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">Multi Site Dashboard</p>
                    <div class="option-item">
                      <input v-model="editSelectedViews" class="cursor-pointer" type="checkbox" id="edit-multi-site-view" name="edit-multi-site-view" value="multi_site" />
                      <label for="edit-multi-site-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="editSelectedActions" class="cursor-pointer" type="checkbox" id="edit-multi-site-action" name="edit-multi-site-action" value="multi_site" />
                      <label for="edit-multi-site-action">Full Control</label>
                    </div>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">Single Site Dashboard</p>
                    <div class="option-item">
                      <input v-model="editSelectedViews" class="cursor-pointer" type="checkbox" id="edit-single-site-view" name="edit-single-site-view" value="single_site" />
                      <label for="edit-single-site-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="editSelectedActions" class="cursor-pointer" type="checkbox" id="edit-single-site-action" name="edit-single-site-action" value="single_site" />
                      <label for="edit-single-site-action">Full Control</label>
                    </div>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">Analytic</p>
                    <div class="option-item">
                      <input v-model="editSelectedViews" class="cursor-pointer" type="checkbox" id="edit-analytic-view" name="edit-analytic-view" value="data_analytic" />
                      <label for="edit-analytic-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="editSelectedActions" class="cursor-pointer" type="checkbox" id="edit-analytic-action" name="edit-analytic-action" value="data_analytic" />
                      <label for="edit-analytic-action">Full Control</label>
                    </div>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">Device Management</p>
                    <div class="option-item">
                      <input v-model="editSelectedViews" class="cursor-pointer" type="checkbox" id="edit-device-management-view" name="edit-device-management-view" value="asset" />
                      <label for="edit-device-management-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="editSelectedActions" class="cursor-pointer" type="checkbox" id="edit-device-management-action" name="edit-device-management-action" value="asset" />
                      <label for="edit-device-management-action">Full Control</label>
                    </div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2 screen-label-font">
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">Alarm Management</p>
                    <div class="option-item">
                      <input v-model="editSelectedViews" class="cursor-pointer" type="checkbox" id="edit-alarm-management-view" name="edit-alarm-management-view" value="alarm" />
                      <label for="edit-alarm-management-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="editSelectedActions" class="cursor-pointer" type="checkbox" id="edit-alarm-management-action" name="edit-alarm-management-action" value="alarm" />
                      <label for="edit-alarm-management-action">Full Control</label>
                    </div>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">Inventory Management</p>
                    <div class="option-item">
                      <input v-model="editSelectedViews" class="cursor-pointer" type="checkbox" id="edit-inventory-management-view" name="edit-inventory-management-view" value="inventory" />
                      <label for="edit-inventory-management-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="editSelectedActions" class="cursor-pointer" type="checkbox" id="edit-inventory-management-action" name="edit-inventory-management-action" value="inventory" />
                      <label for="edit-inventory-management-action">Full Control</label>
                    </div>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">User Management</p>
                    <div class="option-item">
                      <input v-model="editSelectedViews" class="cursor-pointer" type="checkbox" id="edit-user-management-view" name="edit-user-management-view" value="user" />
                      <label for="edit-user-management-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="editSelectedActions" class="cursor-pointer" type="checkbox" id="edit-user-management-action" name="edit-user-management-action" value="user" />
                      <label for="edit-user-management-action">Full Control</label>
                    </div>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">User Group Management</p>
                    <div class="option-item">
                      <input v-model="editSelectedViews" class="cursor-pointer" type="checkbox" id="edit-user-group-management-view" name="edit-user-group-management-view" value="user_group" />
                      <label for="edit-user-group-management-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="editSelectedActions" class="cursor-pointer" type="checkbox" id="edit-user-group-management-action" name="edit-user-group-management-action" value="user_group" />
                      <label for="edit-user-group-management-action">Full Control</label>
                    </div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2 screen-label-font">
                  <v-col cols="12" md="12" class="modal-label-margin">
                    <span>App Access Screens</span>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">Energy</p>
                    <div class="option-item">
                      <input v-model="editSelectedViews" class="cursor-pointer" type="checkbox" id="edit-app-energy-view" name="edit-app-energy-view" value="app_energy" />
                      <label for="edit-app-energy-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="editSelectedActions" class="cursor-pointer" type="checkbox" id="edit-app-energy-action" name="edit-app-energy-action" value="app_energy" />
                      <label for="edit-app-energy-action">Full Control</label>
                    </div>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">Values</p>
                    <div class="option-item">
                      <input v-model="editSelectedViews" class="cursor-pointer" type="checkbox" id="edit-app-value-view" name="edit-app-value-view" value="app_value" />
                      <label for="edit-app-value-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="editSelectedActions" class="cursor-pointer" type="checkbox" id="edit-app-value-action" name="edit-app-value-action" value="app_value" />
                      <label for="edit-app-value-action">Full Control</label>
                    </div>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">Device Management</p>
                    <div class="option-item">
                      <input v-model="editSelectedViews" class="cursor-pointer" type="checkbox" id="edit-app-device-view" name="edit-app-device-view" value="app_device" />
                      <label for="edit-app-device-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="editSelectedActions" class="cursor-pointer" type="checkbox" id="edit-app-device-action" name="edit-app-device-action" value="app_device" />
                      <label for="edit-app-device-action">Full Control</label>
                    </div>
                  </v-col>
                  <v-col cols="3" md="3" class="text-light">
                    <p class="filter-name">Alarm Management</p>
                    <div class="option-item">
                      <input v-model="editSelectedViews" class="cursor-pointer" type="checkbox" id="edit-app-alarm-view" name="edit-app-alarm-view" value="app_alarm" />
                      <label for="edit-app-alarm-view">View Only</label>
                    </div>
                    <div class="option-item">
                      <input v-model="editSelectedActions" class="cursor-pointer" type="checkbox" id="edit-app-alarm-action" name="edit-app-alarm-action" value="app_alarm" />
                      <label for="edit-app-alarm-action">Full Control</label>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-row no-gutters class="mt-3 pt-2">
                <v-spacer></v-spacer>
                <v-col cols="12" class="text-center">
                  <button
                    class="dialog-default-button dialog-button-add-size dialog-bg-outline-button"
                    :ripple="false"
                    type="button"
                    @click="closeDialogEdit"
                  >
                    CANCEL
                  </button>
                  <button
                    class="ml-4 dialog-default-button dialog-button-add-size dialog-bg-grey-button"
                    :ripple="false"
                    type="submit"
                  >
                    CONFIRM
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="confirmDialog"
        persistent
        max-width="672"
        content-class="confirm-dialog-box"
      >
        <v-card>
          <v-card-title>{{ dialogHeading }}</v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" class="dialog-content">
                <span>{{ dialogMessage }}</span>
              </v-col>
              <v-col cols="12" class="dialog-buttons-padding text-center">
                <button
                  class="dialog-default-button dialog-button-confirm-size dialog-bg-outline-button"
                  type="button"
                  :ripple="false"
                  @click="closeConfirmDialog"
                >
                  CANCEL
                </button>
                <button
                  class="ml-4 dialog-default-button dialog-button-confirm-size dialog-bg-grey-button"
                  type="button"
                  :ripple="false"
                  @click="dialogAction(userGroupItem)"
                >
                  CONFIRM
                </button>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="messageDialog"
        max-width="500"
        content-class="confirm-dialog-box"
      >
        <v-card>
          <v-card-title class="justify-content-center">{{ apiTitle }}</v-card-title>
          <v-card-text
            ><v-row no-gutters>
              <v-col cols="12" class="text-center">
                <span v-html="apiMessage"></span>
              </v-col>
              <v-col cols="12" class="dialog-buttons-padding text-center">
                <button
                  class="dialog-default-button dialog-button-confirm-size dialog-bg-grey-button"
                  type="button"
                  :ripple="false"
                  @click="closeMessage"
                >
                  OK
                </button>
              </v-col>
            </v-row></v-card-text
          >
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<style lang="scss" src="@/assets/css/general-datatable-page.scss"></style>
<script>
import { userGroupService } from "@/services";
export default {
  data() {
    return {
      messageDialog: false,
      apiMessage: "",
      apiTitle: "",
      headers: [
        { text: "No", value: "id", align: 'center', sortable: false },
        { text: "User Group", value: "name", align: 'center', sortable: false },
        { text: "Access Screen (s)", value: "accessible_menus", align: 'center', sortable: false },
        { text: "Action", value: "actions", sortable: false, align: 'center', filterable: false },
      ],
      dialogAddUserGroup: false,
      dialogEditUserGroup: false,
      confirmDialog: false,
      dialogAction: () => null,
      dialogHeading: "",
      dialogMessage: "",
      userGroupItem: {},
      dataForm: {},
      dataFormGroup: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      userGroupName: "",
      userGroupType: "",
      userGroupTypesList: [
        {
          value: "super_admin",
          name: "Superadmin"
        },
        {
          value: "admin",
          name: "Admin"
        },
        {
          value: "operator",
          name: "Operator"
        },
        {
          value: "site_engineer",
          name: "Site Engineer"
        },
        {
          value: "home_user",
          name: "Home User"
        },
      ],
      editUserGroupName: "",
      editUserGroupType: "",
      userGroupNameRules: [
        (v) => !!v || "User Group Name is required",
      ],
      userGroupTypeRules: [
        (v) =>!!v || "User Group Type is required",
      ],
      selectedViews: [],
      selectedActions: [],
      editSelectedViews: [],
      editSelectedActions: [],
      userGroupInfo: null,
    };
  },
  async created() {
    this.getListUserGroups();
  },
  methods: {
    getListUserGroups() {
      userGroupService.getListUserGroups().then((res) => {
        if(res?.data?.data) {
          const data = res.data.data;
          this.dataFormGroup = [...data];
        }
      });
    },
    deleteUserGroup(item) {
      if (item?.id) {
        userGroupService
          .deleteUserGroup(item.id)
          .then((res) => {
            if (res.status !== 200 && res.status !== 204) throw res;
            this.closeConfirmDialog();
            this.apiMessage = "User group has been successfully deleted";
            this.apiTitle = "Success";
            this.messageDialog = true;
            this.getListUserGroups();
          })
          .catch((err) => {
            let message = "User group cannot be deleted";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    async submitAdd() {
      if (this.$refs.addForm.validate()) {
        const data = this.prepareData(
          this.userGroupName,
          this.userGroupType,
          this.selectedViews,
          this.selectedActions,
        );
        await userGroupService
          .storeUserGroup(data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.getListUserGroups();
            this.closeDialogAddUserGroup();
            this.apiMessage = "User group has been successfully created";
            this.apiTitle = "Success";
            this.messageDialog = true;
          })
          .catch((err) => {
            let message = "User group cannot be created";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    revertUserGroup() {
      this.$refs.addForm.reset();
    },
    openDialogAddUserGroup() {
      this.dialogAddUserGroup = true;
    },
    closeDialogAddUserGroup() {
      this.revertUserGroup();
      this.selectedViews = [];
      this.selectedActions = [];
      this.dialogAddUserGroup = false;
    },
    openConfirmDialog(item, action, heading, message) {
      this.userGroupItem = Object.assign({}, this.userGroupItem, item);
      this.dialogAction = action;
      this.dialogHeading = heading;
      this.dialogMessage = message;
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.userGroupItem = Object.assign({}, this.userGroupItem);
      this.confirmDialog = false;
      this.dialogAction = () => null;
      this.dialogHeading = "";
      this.dialogMessage = "";
    },
    closeMessage() {
      this.messageDialog = false;
    },
    prepareData(user_group_name, user_group_type, selected_views, selected_actions) {
      const data = {
        name: user_group_name,
        accessible_menus: {
          user: {
            view: false,
            action: false
          },
          user_group: {
            view: false,
            action: false
          },
          alarm: {
            view: false,
            action: false
          },
          asset: {
            view: false,
            action: false
          },
          install: {
            view: false,
            action: false
          },
          inventory: {
            view: false,
            action: false
          },
          multi_site: {
            view: false,
            action: false
          },
          single_site: {
            view: false,
            action: false
          },
          data_analytic: {
            view: false,
            action: false
          },
          app_energy: {
            view: false,
            action: false
          },
          app_value: {
            view: false,
            action: false
          },
          app_device: {
            view: false,
            action: false
          },
          app_alarm: {
            view: false,
            action: false
          }
        },
      };
      selected_views.map((value) => {
        data.accessible_menus[value]['view'] = true;
      });
      selected_actions.map((value) => {
        data.accessible_menus[value]['action'] = true;
      });
      if (user_group_type) {
        data.type = user_group_type;
      }
      return data;
    },
    setUserGroupInfo(data) {
      if (data) {
        this.editUserGroupName = data.name || "";
        this.editUserGroupType = data.type || "";
        if (data.accessible_menus) {
          const selectedViews = Object.keys(data.accessible_menus).reduce(
            (acc, cur) => {
              if (data.accessible_menus[cur]['view']) {
                acc.push(cur);
              }
              return acc;
            },
            []
          );
          const selectedActions = Object.keys(data.accessible_menus).reduce(
            (acc, cur) => {
              if (data.accessible_menus[cur]['action']) {
                acc.push(cur);
              }
              return acc;
            },
            []
          );
          this.editSelectedViews = [...selectedViews];
          this.editSelectedActions = [...selectedActions];
        }
        this.userGroupInfo = data;
      } else {
        this.editUserGroupName = "";
        this.editUserGroupType = "";
        this.editSelectedViews = [];
        this.editSelectedActions = [];
        this.userGroupInfo = null;
      }
    },
    openDialogEdit(item) {
      if (item?.id) {
        userGroupService
          .getUserGroup(item.id)
          .then((res) => {
            if (res?.data?.data) {
              this.setUserGroupInfo(res.data.data);
              this.dialogEditUserGroup = true;
            }
          })
          .catch();
      }
    },
    closeDialogEdit() {
      this.setUserGroupInfo(null);
      this.revertUserEdit();
      this.dialogEditUserGroup = false;
    },
    submitEdit() {
      if (this.$refs.editForm.validate()) {
        const data = this.prepareData(
          this.editUserGroupName,
          null,
          this.editSelectedViews,
          this.editSelectedActions,
        );
        userGroupService
          .updateUserGroup(this.userGroupInfo.id, data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.apiMessage = "User group's information has been successfully updated";
            this.apiTitle = "Success";
            this.messageDialog = true;
            this.getListUserGroups();
            this.closeDialogEdit();
            this.editSelectedViews = [];
            this.editSelectedActions = [];
          })
          .catch((err) => {
            let message = "User group's information cannot be updated";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    revertUserEdit() {
      this.$refs.editForm.reset();
    },
  },
};
</script>
